<template>
  <div>
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item prop="username" label="用户名">
        <el-input v-model="form.username"></el-input>
      </el-form-item>
      <el-form-item prop="mobileNumber" label="手机号码">
        <el-input v-model="form.mobileNumber"></el-input>
      </el-form-item>
      <el-form-item prop="realName" label="真实姓名">
        <el-input v-model="form.realName"></el-input>
      </el-form-item>
      <el-form-item prop="roleIdList" label="角色">
        <el-select v-model="form.roleIdList" multiple placeholder="请选择">
          <el-option v-for="item in roleList" :key="item.id" :label="item.name" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="status" label="状态">
        <el-switch v-model="form.status" :active-value="1" :inactive-value="0"></el-switch>
      </el-form-item>
      <el-form-item prop="password" label="密码">
        <el-input v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item prop="rePassword" label="确认密码">
        <el-input v-model="form.rePassword"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">确定</el-button>
        <el-button @click="close">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          username: '',
          mobileNumber: '',
          realName: '',
          roleIdList: [],
          status: 1,
          password: '',
          rePassword: ''
        },
        rules: {
          username: [{
              required: true,
              message: '请填写用户名',
              trigger: 'blur'
            },
            {
              pattern: /^\S{2,32}$/,
              message: '请正确填写用户名',
              trigger: 'blur'
            }
          ],
          mobileNumber: [{
              required: true,
              message: '请填写手机号码',
              trigger: 'blur'
            },
            {
              pattern: /^1[3-9][0-9]{9}$/,
              message: '请正确填写手机号码',
              trigger: 'blur'
            }
          ],
          realName: [{
              required: true,
              message: '请填写真实姓名',
              trigger: 'blur'
            },
            {
              pattern: /^\S{2,32}$/,
              message: '请正确填写真实姓名',
              trigger: 'blur'
            }
          ],
          password: [{
              required: true,
              message: '请填写密码',
              trigger: 'blur'
            },
            {
              pattern: /^\S{6,32}$/,
              message: '请正确填写密码',
              trigger: 'blur'
            }
          ],
          rePassword: [{
              required: true,
              message: '请填写确认密码',
              trigger: 'blur'
            },
            {
              pattern: /^\S{6,32}$/,
              message: '请正确填写确认密码',
              trigger: 'blur'
            }
          ]
        },
        roleList: []
      }
    },
    created() {
      this.getRoleList();
    },
    methods: {
      close() {
        this.$refs.form.resetFields()
        this.$emit('close', true)
      },
      onSubmit() {
        this.$refs.form.validate((valid) => {
          console.log(valid)
          if (!valid) {
            return false;
          }

          if (this.form.password !== this.form.rePassword) {
            this.$alert('两次输入的密码不一致', '新增用户', {
              type: 'error'
            })
            return false
          }

          this.$http.post('/user', this.form).then(({
            data: res
          }) => {
            let {
              code,
              msg,
              data
            } = res
            console.log(code, msg, data)

            if (code === 0) {
              this.$message.success('新增成功')
              this.close();
            }

            if (code !== 0) {
              this.$message.error(msg)
            }
          })
        })
      },
      getRoleList() {
        this.$http.get('/user/role', {
          params: {}
        }).then(({
          data: res
        }) => {
          let {
            code,
            msg,
            data
          } = res
          console.log(code, msg, data)

          if (code === 0 && data) {

            let records = data
            this.roleList = records && records.length ? records : []
          }

          if (code !== 0) {
            this.$message.error(msg)
          }
        })
      }
    }
  }
</script>

<style>
</style>
